import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children }) => {
    const user = useSelector((state) => state.user.value);
    const navigate = useNavigate();

    useEffect(() => {
        if (user?.can_play_lottery) {
            navigate("/contests");
        }
    }, [user, navigate]);

    return children;
};

export default ProtectedRoute;
