import React from 'react';
import './Header.css';

const Header = () => {
    return (
        <div className="header">
            <h1>Выполняй задания</h1>
            <p className="alt-font">И зарабатывай WinCoins, чтобы обменять их на фрибеты от Winline</p>
            <hr/>
        </div>
    );
};

export default Header;
