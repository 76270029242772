import React from 'react';
import "./Information.css";

export const Information = () => {
    return (
        <div className="faq-information">
            <p className="faq-title">
                FAQ
            </p>
            <p className="faq-text">
                Если у вас остались вопросы — <br/>
                напишите нам
            </p>
            <a href="https://t.me/AlbertooBigFARGO" className="faq-window super-border">
                <img width="40" height="40" src="/images/tg.svg" alt="email-icon" className="email-icon"/>
                <span className="faq-email">@AlbertooBigFARGO</span>
                <div className="email-btn">
                    <img width="40" height="40" src="/images/link.svg" alt="link-icon" className="email-btn-icon"/>
                </div>
            </a>
        </div>
    );
};
