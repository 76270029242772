import React from 'react';
import './TaskList.css';
import CountdownTimer from "../dailyBonus/CountdownTimer";

const TaskList = ({tasks}) => (
    <div className="task-list">
        <h2>Задания</h2>
        {tasks.map((task, index) => (

            <div key={index} className={"task-item super-border" + (!task.is_available ? ' --disabled' : '')}>
                <img width="24" height="24" src="/images/fishka.png" alt="Task Icon" className="img-chip"/>
                <div className="task-content">
                    <span className="task-text">{task.name}</span>
                    <div className="task-info">
                        <div className="coin-section">
                            <img src="/images/coin100.png" width="100" height="100" alt="Coin Icon" className="coin-icon"/>
                            <span className="coin-amount">{task.reward.toLocaleString()}</span>
                        </div>
                        {task.current_streak !== undefined && (
                            <div className="progress-bar-parent">
                                <div className="progress-bar">
                                    {Array(7).fill(0).map((_, i) => (
                                        <div key={i}
                                             className={`progress-dot ${i < task.current_streak ? 'active' : ''}`}/>
                                    ))}
                                </div>
                                {
                                    task.is_completed && (
                                        <div className="can-repeated">
                                            Можно повторить через: <span><CountdownTimer/></span>
                                        </div>
                                    )
                                }
                            </div>
                        )}
                    </div>
                </div>
                {
                    task.is_completed ? (<img width="32" height="33" src="/images/check-circle-yes.svg" alt="Done Icon"/>) :

                        task.is_available ? (

                            <a href={task.link} target="_blank" rel="noreferrer">
                                <img width="32" height="32" src="/images/link.svg" alt="Connection Icon"/>
                            </a>
                        ) : (
                            <img width="32" height="33" src="/images/lock.svg" alt="Lock Icon"/>
                        )
                }
            </div>
        ))}
    </div>
);

export default TaskList;
