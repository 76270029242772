import React from 'react';
import './TeamList.css';

const TeamList = ({ teams, selectedTeam, onSelectTeam }) => (
    <div className="team-list">
        {teams.map((data, index) => (
            <div
                key={data.team.id}
                className={`team-item ${selectedTeam && selectedTeam.id === data.team.id ? 'selected' : ''} `}
                onClick={() => onSelectTeam(data.team)}
            >
                <div className="team-info">
                    <img width={64} height={64} src={data.team.logo_url} alt={data.team.name} className="team-logo"/>
                    <div className="team-number"><div>{index + 1}</div></div>
                </div>
                <div>
                    <span className="team-name">{data.team.short_name}</span>
                </div>
            </div>
        ))}
    </div>
);

export default TeamList;
