import React, { useEffect, useCallback } from 'react';
import FriendsInfo from "./components/friendsInfo/FriendsInfo";
import ShareButton from "./components/shareButton/ShareButton";
import NavigationBar from "../app/components/navigationBar/NavigationBar";
import Header from "./components/header/Header";
import './Friends.css';
import { getFriends } from "../../api/friends";
import { useDispatch, useSelector } from "react-redux";
import { setFriends } from "../../store/friendsSlice";
import { useInitDataRaw } from "../../utils/tg";
import ReferralLink from "./components/referralLink/ReferralLink";
import LayoutMain from "../app/components/layout/LayoutMain";
import {logError} from "../../utils/errorHandler";

const Friends = () => {

    const friendsData = useSelector((state) => state.friends.value);
    const initDataRaw = useInitDataRaw();
    const dispatch = useDispatch();
    const handleSetFriends = useCallback((friends) => dispatch(setFriends(friends)), [dispatch]);

    useEffect( () => {
        const fetchFriends = async () => {
            try {
                const response = await getFriends(initDataRaw);
                handleSetFriends(response.data);
            } catch (error) {
                logError(error.message, 'Friends fetchFriends', 0, 0, error);
                console.error("Error fetching friends:", error);
            }
        };

        fetchFriends();
    }, [handleSetFriends, initDataRaw]);

    return (
        <LayoutMain>
            <div className="friends">
                <Header/>
                <div className="friend-cards">

                    <div className="friend-card super-border">
                        <img width="40" height="40" src="/images/tg.svg" alt="icon" className="tg-icon"/>
                        <p className="title">Друг с простым ТГ аккаунтом</p>
                        <p className="coins"><img width="100" height="100" src="/images/coin100.png" alt=""/>10,000</p>
                    </div>

                    <div className="friend-card super-border">
                        <img width="61" height="61" src="/images/tg-prem.svg" alt="icon" className="tg-icon"/>
                        <p className="title">Друг с Premium ТГ аккаунтом</p>
                        <p className="coins"><img  width="100" height="100" src="/images/coin100.png" alt=""/>20,000</p>
                    </div>

                </div>
                <FriendsInfo/>
                <div className="bottom_section">
                    <ReferralLink text={friendsData.my_link}/>
                    <ShareButton text={friendsData.my_link}/>
                </div>
            </div>
            <div className="friends-footer">
                <NavigationBar/>
            </div>
        </LayoutMain>
    );
};

export default Friends;
