import React, {useEffect} from 'react';
import './EarningsPage.css';
import UserList from "./components/userList/UserList";
import LayoutMain from "../../app/components/layout/LayoutMain";
import {useSelector} from "react-redux";
import {initBackButton} from "../../../utils/tg";
import ReferralLink from "../components/referralLink/ReferralLink";
import ShareButton from "../components/shareButton/ShareButton";

const EarningsPage = () => {
    const data = useSelector((state) => state.friends.value);

    useEffect(() => {
        initBackButton();
    }, []);

    return (
        <LayoutMain addClass="no-padding-bottom" >
            <div className="earnings-page">
                <div className="earnings-display">
                    <p>Заработано по приглашениям</p>

                    <div className="counter-value">
                        <img className="coin-icon-main" width="100" height="100" src="/images/coin100.png" alt=""/>
                        <div>{data.total.toLocaleString()}</div>
                    </div>

                    <hr/>
                </div>

                <UserList users={data.friends}/>

                <div className="bottom_section">
                    <ReferralLink text={data.my_link}/>
                    <ShareButton text={data.my_link}/>
                </div>


            </div>
        </LayoutMain>
    );
};

export default EarningsPage;
