export const getPercentageColor = (percentage) => {

    const normalizedPercentage = Math.min(100, Math.max(0, percentage));

    const hexToRgb = (hex) => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        return [r, g, b];
    };

    const redColor = hexToRgb('#D22730');
    const yellowColor = hexToRgb('#FFE764');
    const greenColor = hexToRgb('#4ABA24');

    let targetColors;
    let localPercentage;

    if (normalizedPercentage <= 50) {
        targetColors = [redColor, yellowColor];
        localPercentage = (normalizedPercentage * 2);
    } else {
        targetColors = [yellowColor, greenColor];
        localPercentage = (normalizedPercentage - 50) * 2;
    }

    const result = targetColors[0].map((start, i) => {
        const end = targetColors[1][i];
        return Math.round(start + (end - start) * (localPercentage / 100));
    });

    return `rgb(${result[0]}, ${result[1]}, ${result[2]})`;

};

export const getColorByPercentageFromWhite = (percentage) => {

    const clampedPercentage = Math.max(0, Math.min(100, percentage));

    // Adjusted transition to accelerate green at lower percentages
    const adjustedPercentage = Math.pow(clampedPercentage / 100, 0.5) * 100;

    // Target green color: #4ABA24
    const targetGreen = {
        r: 74,  // 0x4A
        g: 186, // 0xBA
        b: 36   // 0x24
    };

    // Calculate color components
    const white = 255;
    const r = Math.round(white - (adjustedPercentage / 100) * (white - targetGreen.r));
    const g = Math.round(white - (adjustedPercentage / 100) * (white - targetGreen.g));
    const b = Math.round(white - (adjustedPercentage / 100) * (white - targetGreen.b));

    // Return RGB color string
    return `rgb(${r}, ${g}, ${b})`;
}