import React from 'react';
import './LoseResult.css';

const LoseResult = () => {
    const handleReturnClick = () => {
        window.location.reload();
    };

    return (
        <div className="lose-result">
            <img src="/images/no-gift.svg" width="129" height="128" alt="" className="lose-result-icon" />
            <h1>Увы, в этот раз <br /> не повезло</h1>
            <p className="alt-font">
                <span className="highlight">Продолжай принимать участие</span> в ежедневных конкурсах и тебе обязательно повезёт!
            </p>
            <button className="lose-result-button" onClick={handleReturnClick}>
                Вернуться к конкурсам
            </button>
        </div>
    );
};

export default LoseResult;
