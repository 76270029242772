import React, {useCallback, useEffect} from 'react';
import './Contests.css';
import ContestHeader from "./components/contestHeader/ContestHeader";
import EarningsInput from "./components/earningsInput/EarningsInput";
import NavigationBar from "../app/components/navigationBar/NavigationBar";
import ContestPrizes from "./components/contestPrizes/ContestPrizes";
import Lottery from "./components/lottery/Lottery";
import LayoutMain from "../app/components/layout/LayoutMain";
import { setGames } from "../../store/gamesSlice";
import {useDispatch, useSelector} from "react-redux";
import {useInitDataRaw} from "../../utils/tg";
import {getGames} from "../../api/games";
import {logError} from "../../utils/errorHandler";

const Contests = () => {

    const gamesData = useSelector((state) => state.games.value);
    const initDataRaw = useInitDataRaw();
    const dispatch = useDispatch();
    const handleSetGames = useCallback((games) => dispatch(setGames(games)), [dispatch]);

    useEffect( () => {
        const fetchGames = async () => {
            try {
                const response = await getGames(initDataRaw);
                handleSetGames(response.data);
            } catch (error) {
                logError(error.message, 'Contests fetchGames', 0, 0, error);
                console.error("Error fetching games:", error);
            }
        };
        fetchGames();
    }, [handleSetGames, initDataRaw]);

    return (
        <LayoutMain addClass={gamesData && gamesData.available_lottery ?'no-padding-bottom' :''}>
            <div className="contests">
                {gamesData && gamesData.available_lottery ? (
                        <Lottery gamesData={gamesData}/>
                ) : (
                    <>
                        <ContestHeader/>
                        <EarningsInput rewardsCount={gamesData.rewards_count}/>
                        <ContestPrizes gamesData={gamesData}/>
                    </>
                )}
            </div>
            {gamesData && gamesData.available_lottery ? (<></>)
                : (
                    <div className="contests-footer">
                        <NavigationBar/>
                    </div>
                )}
        </LayoutMain>
    );
};

export default Contests;
