import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    value: {
        id: 0,
        coins: 0,
        coins_daily: 0,
        coins_monthly: 0,
        energy: 200,
        max_energy: 200,
        daily_chance: 0,
        monthly_chance: 0,
        lvl: 1,
        first_name: 'Name',
        last_name: 'LastName',
        username: 'username',
        photo_ext: null,
        exp_min: 0,
        exp_current: 0,
        exp_max: 200,
        is_winline_linked: false,
        can_play_lottery: false,
    },
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setValue: (state, action) => {
            state.value = action.payload;
        },

        incrementCoinsDaily: (state, action) => {
            state.value.coins_daily += action.payload;
        },
        incrementCoinsMonthly: (state, action) => {
            state.value.coins_monthly += action.payload;
        },
        incrementExp: (state, action) => {

            if (state.value.exp_current >= state.value.exp_max - 1) {
                state.value.lvl += 1
                state.value.max_energy += 200
                state.value.exp_current = state.value.exp_min
                state.value.energy = state.value.max_energy
            } else {
                state.value.exp_current += action.payload;
            }

        },
    },
});

export const getProfilePhoto = (id, ext) => {
    return  ext ? (process.env.REACT_APP_API_URL + `/storage/img/${id}.${ext}`) : '/images/avatar-placeholder.png';
};


export const {setValue, incrementCoinsDaily, incrementCoinsMonthly, incrementExp} = userSlice.actions;
export default userSlice.reducer;
