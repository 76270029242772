import {
    backButton,
    initData,
    useSignal,
    viewport,
    closingBehavior,
    swipeBehavior, postEvent,
} from '@telegram-apps/sdk-react';


export const useInitDataRaw = () => {
    return useSignal(initData.raw);
};

export const useInitDataState = () => {
    return useSignal(initData.state);
};

export const initBackButton = () => {
    backButton.show();
    backButton.onClick(() => {
        window.history.back();
    });
};

export const hideBackButton = () => {
    backButton.hide();
};


export const enableHapticFeedBack = (style) => {
    postEvent('web_app_trigger_haptic_feedback',{type: 'impact', impact_style: style || 'medium' })
};

export const expandApp = () => {
    viewport.expand();
};

export const enableConfirmation = () => {
    if (closingBehavior.isMounted) {
        closingBehavior.enableConfirmation();
    }
}

export const disableConfirmation = () => {
    if (closingBehavior.isMounted) {
        closingBehavior.disableConfirmation();
    }
}

export const disableSwipes = () => {
    if (swipeBehavior.isMounted) {
        swipeBehavior.disableVertical();
    }
}