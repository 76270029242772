import React, { useCallback, useEffect } from 'react';
import "./Tasks.css";
import Header from "./components/header/Header";
import DailyBonusBar from "./components/dailyBonus/DailyBonus";
import WinlineCard from "../winline/winlineCard/WinlineCard";
import TaskList from "./components/taskList/TaskList";
import NavigationBar from "../app/components/navigationBar/NavigationBar";
import { useInitDataRaw } from "../../utils/tg";
import { getTasks } from "../../api/tasks";
import LayoutMain from "../app/components/layout/LayoutMain";
import { useDispatch, useSelector } from "react-redux";
import { setTasks } from "../../store/tasksSlice";
import {logError} from "../../utils/errorHandler";

const Tasks = () => {
    const tasksData = useSelector((state) => state.tasks.value);
    const initDataRaw = useInitDataRaw();
    const dispatch = useDispatch();
    const handleSetTasks = useCallback((tasks) => dispatch(setTasks(tasks)), [dispatch]);

    useEffect( () => {
        const fetchTasks = async () => {
            try {
                const response = await getTasks(initDataRaw);
                handleSetTasks(response.data);
            } catch (error) {
                logError(error.message, 'Tasks fetchTasks', 0, 0, error);
                console.error("Error fetching tasks:", error);
            }
        };

        fetchTasks();
    }, [handleSetTasks, initDataRaw]);

    return (
        <LayoutMain>
            <div className="tasks">
                <Header />
                <DailyBonusBar dailyLoginData={tasksData?.daily_logins} />
                <WinlineCard isWinlineLinked={tasksData?.is_winline_linked} />
                <TaskList tasks={tasksData?.tasks} />
            </div>
            <NavigationBar />
        </LayoutMain>
    );
};

export default Tasks;
