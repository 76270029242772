import React, { createContext, useContext } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Создаем контекст
const ToastContext = createContext();

// Создаем провайдер для контекста
export const ToastProvider = ({ children }) => {
    const notify = (message, type = 'default') => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            case 'info':
                toast.info(message);
                break;
            case 'warning':
                toast.warn(message);
                break;
            default:
                toast(message);
        }
    };


    return (
        <ToastContext.Provider value={notify}>
            {children}
        </ToastContext.Provider>
    );
};

// Хук для использования контекста
export const useToast = () => {
    return useContext(ToastContext);
};