import React from 'react';
import './FriendsInfo.css';
import { Link } from "react-router-dom";
import {useSelector} from "react-redux";

const FriendsInfo = () => {
    const data = useSelector((state) => state.friends.value);

    return (
        <Link to="/earnings">
            <div className="friends-info super-border">
                <div className="friends-info-icon">
                    <img src="/images/friends.svg" alt="icon" width="24" height="24"/>
                </div>
                <div className="friends-info-text">
                    <div className="friends-info-title">Приглашённые друзья</div>
                    <div className="friends-info-details">
                        <span className="friends-info-count">{data.count} человек.</span>
                        <span className="friends-info-earnings">
                            <span className="earned">Заработано:</span>
                            <span className="friends-info-earnings-icon">
                                <img src="/images/coin100.png" width="100" height="100" alt="coin icon"/>
                            </span>
                            <span className="earnings">{data.total.toLocaleString()}</span>
                        </span>
                    </div>
                </div>
                <div className="friends-info-arrow"><img width="20" height="20" src="/images/friends/chevron-right.png" alt=""/></div>
            </div>
        </Link>
    );
};

export default FriendsInfo;
