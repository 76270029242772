export function CQFetchPOST(data) {
    const url = process.env.REACT_APP_API_URL + '/2342-log-js-errors';

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(data),
    })
}