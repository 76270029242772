import React, { useEffect, useState } from 'react';
import './ContestPrizes.css';
import PrizeCard from "../prizeCard/PrizeCard";

const ContestPrizes = ({gamesData}) => {

    const [activeTab, setActiveTab] = useState('daily');
    const [timeLeft, setTimeLeft] = useState(null);

    const calculateTimeLeft = (date) => {
        const now = new Date();
        const currentTimeInMSK = new Date(now.toLocaleString('en-US'));
        const targetDate = new Date(date);

        const difference = targetDate - currentTimeInMSK;

        if (difference > 0) {
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / 1000 / 60) % 60);
            const seconds = Math.floor((difference / 1000) % 60);

            return {
                days,
                hours,
                minutes,
                seconds,
            };
        } else {
            return {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        }
    };

    useEffect(() => {
        if (gamesData) {
            const date = activeTab === 'daily' ? gamesData.daily.date : gamesData.monthly.date;

            const updateTimer = () => {
                setTimeLeft(calculateTimeLeft(date));
            };

            updateTimer();
            const timerInterval = setInterval(updateTimer, 1000);

            return () => clearInterval(timerInterval);
        }
    }, [gamesData, activeTab]);

    const renderPrizes = (prizes) => {
        return Object.keys(prizes).map((amount, index) => {
            const chance = prizes[amount];
            return <PrizeCard key={index} amount={amount} chance={chance} />;
        });
    };

    return (
        <div className="contest-prize">
            <div className="contest-tabs">
                <div
                    className={`contest-tab super-border ${activeTab === 'daily' ? '--selected' : ''}`}
                    onClick={() => setActiveTab('daily')}
                >
                    ЕЖЕДНЕВНЫЙ
                </div>
                <div
                    className={`contest-tab super-border ${activeTab === 'monthly' ? '--selected' : ''}`}
                    onClick={() => setActiveTab('monthly')}
                >
                    ЕЖЕМЕСЯЧНЫЙ
                </div>
            </div>

            <div className="contest-timer">
                {timeLeft ? (
                    <span>
                        {timeLeft.days > 0 ? `${timeLeft.days}д. ` : ''}
                        {`${timeLeft.hours}ч. ${timeLeft.minutes}м`}
                    </span>
                ) : (
                    <span>Таймер истек</span>
                )}
                <p className="contest-timer-text">до проведения розыгрыша</p>
            </div>

            <div className="contest-prizes">
                {activeTab === 'daily' && renderPrizes(gamesData.daily.prizes)}
                {activeTab === 'monthly' && renderPrizes(gamesData.monthly.prizes)}
            </div>
            <button className="try-luck-button disabled">Испытать удачу</button>
        </div>
    );
};

export default ContestPrizes;
