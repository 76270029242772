import React from 'react';
import './Header.css';

const Header = () => {
    return (
        <div className="header">
            <h1>Зови друзей</h1>
            <p className="alt-font">И получай WinCoins за каждого друга <br></br><span>+ 1% каждый день</span> с его заработка</p>
        </div>
    );
};

export default Header;
