import React, {useCallback, useEffect, useRef, useState} from 'react';
import './MainCounter.css';
import Energy from '../../../tap/components/energy/Energy';
import {useDispatch, useSelector} from "react-redux";
import {enableConfirmation, enableHapticFeedBack, useInitDataRaw} from "../../../../utils/tg";
import {incrementCoinsDaily, incrementCoinsMonthly, incrementExp, setValue} from "../../../../store/userSlice";
import 'react-toastify/dist/ReactToastify.css';
import {setClicks} from "../../../../api/clicks";
import {logError} from "../../../../utils/errorHandler";
import StatsSection from "../statsSection/StatsSection";

const MainCounter = () => {
    const user = useSelector((state) => state.user.value);
    const [coins, setCoins] = useState(user?.coins);
    const [coinAnimations, setCoinAnimations] = useState([]);
    const [energy, setEnergy] = useState(user?.energy);
    const [maxEnergy, setMaxEnergy] = useState(user?.max_energy);
    const [newClicks, setNewClicks] = useState(0);
    const lastEventRef = useRef(null);
    const energyRef = useRef(energy);
    const [isClicked, setIsClicked] = useState(false);
    const timerRef = useRef(null);
    const energyIntervalRef = useRef(null);
    const clickIntervalRef = useRef(null);
    const newClicksRef = useRef(newClicks);
    const dispatch = useDispatch();

    const handleSetUser = useCallback((user) => {
        dispatch(setValue(user));
    }, [dispatch]);

    const handleIncrementCoinsDaily = (value) => {
        dispatch(incrementCoinsDaily(value));
    };

    const handleIncrementCoinsMonthly = (value) => {
        dispatch(incrementCoinsMonthly(value));
    };

    const handleIncrementExp = (value) => {
        dispatch(incrementExp(value))
    };

    const initData = useInitDataRaw();

    useEffect(() => {
        const handleSetClick = async (clickCount) => {
            if (clickCount > 0) {
                try {
                    setNewClicks(0);
                    const response = await setClicks(initData, {clicks: clickCount})
                    const state = response.data.state;

                    let coinDiff = newClicksRef.current * Math.min(energyRef.current, user?.lvl)
                    // учитываем монеты, заработанные за время отправки запроса
                    state.coins += coinDiff
                    state.coins_daily += coinDiff
                    state.coins_monthly += coinDiff
                    state.exp_current += coinDiff
                    state.energy = Math.max(state.energy - coinDiff, 0)

                    handleSetUser(state);
                } catch (error) {
                    logError(error.message, 'MainCounter handleSetClick', 0, 0, error);
                    console.error('Error setting user clicks:', error);
                }
            }
        };

        newClicksRef.current = newClicks;

        if (newClicks > 0) {
            enableConfirmation();

            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }

            if (!clickIntervalRef.current) {
                clickIntervalRef.current = setInterval(() => {
                    handleSetClick(newClicksRef.current);
                    newClicksRef.current = null;

                }, 5000);
            }

            timerRef.current = setTimeout(() => {
                handleSetClick(newClicks);
                clearInterval(clickIntervalRef.current);
                clickIntervalRef.current = null;
            }, 1000);
        }

        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [newClicks, initData, handleSetUser, user?.lvl]);

    useEffect(() => {

        setEnergy(user.energy);
        energyRef.current = user.energy;

    }, [user.energy]);

    useEffect(() => {

        setMaxEnergy(user.max_energy);


    }, [user.max_energy]);

    useEffect(() => {

        setCoins(user.coins);

    }, [user.coins]);

    useEffect(() => {
        energyIntervalRef.current = setInterval(() => {
            setEnergy((prevEnergy) => {
                if (prevEnergy < maxEnergy) {
                    const newEnergy = prevEnergy + 1;
                    energyRef.current = newEnergy;
                    return newEnergy;
                }
                return prevEnergy;
            });
        }, 3000);

        return () => {
            clearInterval(energyIntervalRef.current);
        };
    }, [maxEnergy]);

    const handleInteraction = (positions) => {


        if (energyRef.current === 0) {
            return;
        }

        const coinsPerClick = Math.min(energyRef.current, user?.lvl)

        enableHapticFeedBack();

        if (energyRef.current === 0) {
            return;
        }

        handleIncrementCoinsDaily(coinsPerClick);
        handleIncrementCoinsMonthly(coinsPerClick);
        handleIncrementExp(coinsPerClick);

        setEnergy((prevEnergy) => {
            const newEnergy = prevEnergy - coinsPerClick;
            energyRef.current = newEnergy;
            return newEnergy;
        });

        setCoins((prevCoins) => prevCoins + coinsPerClick);
        setNewClicks((prevNewClicks) => prevNewClicks + 1);


        const newAnimations = positions.map((position) => ({
            id: Date.now() + Math.random(),
            x: position.x,
            y: position.y,
            value: `+` + coinsPerClick
        }));

        setCoinAnimations((prevAnimations) => [...prevAnimations, ...newAnimations]);

        setIsClicked(true);
        setTimeout(() => setIsClicked(false), 100);

        setTimeout(() => {
            setCoinAnimations((prevAnimations) =>
                prevAnimations.filter((anim) => !newAnimations.includes(anim))
            );
        }, 1000);
    }

    const handleClick = (event) => {

        if (lastEventRef.current === 'touch') return;
        lastEventRef.current = 'click';

        handleInteraction([{x: event.clientX, y: event.clientY}]);

    };

    const handleTouchStart = (event) => {

        if (lastEventRef.current === 'click') return;
        lastEventRef.current = 'touch';

        const touchPositions = Array.from(event.changedTouches).map((touch) => ({
            x: touch.clientX,
            y: touch.clientY,
        }));


        handleInteraction(touchPositions);

    };

    return (

        <div className="main-section" onClick={handleClick}
             onTouchStart={handleTouchStart}>
            <StatsSection/>
            <div className="background">
                <img src="/images/bg/bg.jpg" alt=""/>
                <div className={"shine" + (isClicked ? " active" : "")}></div>
                <img src={'/images/bg/teams/' + user?.team?.id + '.png'} alt=""/>
            </div>

            <div className="main-counter">
                <div className="counter-value">
                    <img src="/images/coin100.png" alt="coin" className="coin-icon-main" width="100" height="100"/>
                    <div>{coins.toLocaleString('en-US')}</div>
                </div>

                {coinAnimations.map((anim) => (
                    <div
                        key={anim.id}
                        className="coin-animation"
                        style={{left: `${anim.x}px`, top: `${anim.y}px`}}
                    >
                        {anim.value}
                    </div>
                ))}

                <div className="tap-boost">
                    <Energy currentEnergy={energy} maxEnergy={maxEnergy}/>
                </div>
            </div>
        </div>
    );
};

export default MainCounter;
