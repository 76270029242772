import React from 'react';
import './Layout.css';

const Layout = ({ children }) => {
    return (
        <div className="layout-container">
            <main className="content">
                {children}
            </main>
        </div>
    );
};

export default Layout;
