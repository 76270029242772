import React from 'react';
import './Loader.css';

const Loader = () => {
    return (
        <div className="coin-container">
            <img className="coin" src="/images/coin100.png" alt=""/>
        </div>
    );
};

export default Loader;
