import {createBrowserRouter} from "react-router-dom";
import ErrorScreen from "../modules/app/components/errorScreen/ErrorScreen";
import Home from "../modules/app/components/home/Home";
import Faq from "../modules/faq/Faq";
import Tasks from "../modules/task/Tasks";
import Friends from "../modules/friends/Friends";
import EarningsPage from "../modules/friends/earningsPage/EarningsPage";
import Contests from "../modules/contests/Contests";
import WinlineCallback from "../modules/winline/WinlineCallback";
import ProtectedRoute from "./ProtectedRoute";
import Rewards from "../modules/contests/rewards/Rewards";
import Leaders from "../modules/leaders/Leaders";

export const router = createBrowserRouter([
    {
        path: "/leaders",
        element: <Leaders />,
        errorElement: <ErrorScreen />,
    },
    {
        path: "/rewards",
        element: <Rewards />,
        errorElement: <ErrorScreen />,
    },
    {
        path: "/contests",
        element: <Contests />,
        errorElement: <ErrorScreen />,
    },
    {
        path: "/earnings",
        element: (
            <ProtectedRoute>
                <EarningsPage/>
            </ProtectedRoute>
        ),
        errorElement: <ErrorScreen/>,
    },
    {
        path: "/friends",
        element: (
            <ProtectedRoute>
                <Friends/>
            </ProtectedRoute>
        ),
        errorElement: <ErrorScreen/>,
    },
    {
        path: "/tasks",
        element: (
            <ProtectedRoute>
                <Tasks/>
            </ProtectedRoute>
        ),
        errorElement: <ErrorScreen/>,
    },
    {
        path: "/faq",
        element: (
            <ProtectedRoute>
                <Faq/>
            </ProtectedRoute>
        ),
        errorElement: <ErrorScreen/>,
    },
    {
        path: "/winline-callback",
        element: <WinlineCallback/>,
        errorElement: <ErrorScreen/>,
    },
    {
        path: "/",
        element: (
            <ProtectedRoute>
                <Home/>
            </ProtectedRoute>
        ),
        errorElement: <ErrorScreen/>,
    },
]);
