import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: {
       daily: {
           prizes: [],
           date: '',
       },
        monthly: {
           prizes: [],
            date: ''
        },
        available_lottery: null,
        rewards_count: 0
    },
};

export const gamesSlice = createSlice({
    name: 'games',
    initialState,
    reducers: {
        setGames: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setGames } = gamesSlice.actions;
export default gamesSlice.reducer;
