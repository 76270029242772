import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {api} from "../../request";
import { useInitDataRaw } from "../../utils/tg";
import {logError} from "../../utils/errorHandler";

const WinlineCallback = () => {
    const location = useLocation();

    const initData = useInitDataRaw();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');
        const state = queryParams.get('state');


        if (code && state) {
            const data = {code, state};
            api(initData).post('/link-winline', JSON.stringify(data), {

            })
                .then(response => {
                    navigate('/', {state: {toast_success: response.data.message}});
                })
                .catch(error => {
                    logError(error.message, 'WinlineCallback Привязка аккаунта Winline', 0, 0, error);
                    if (error.response) {
                        navigate('/', {state: {toast_error: error.response.data.message}});
                    } else {
                        navigate('/', {state: {toast_error: "Произошла ошибка привязки аккаунта Winline, попробуйте позже"}});
                    }
                });
        }
    }, [location, initData, navigate]);

    return null;
};

export default WinlineCallback;
