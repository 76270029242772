import React from 'react';
import './ShareButton.css';

const ShareButton = ({ text }) => {
    const shareText = 'Заходи! Тут можно выиграть крутые призы 🔥';

    const handleShare = () => {
        const shareUrl = `https://t.me/share/url?url=${text}&text=${shareText}`;
        window.open(shareUrl, '_blank');
    };

    return (
        <button className="share-button" onClick={handleShare}>
            Поделиться
        </button>
    );
};

export default ShareButton;
