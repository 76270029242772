import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import friendsReducer from './friendsSlice';
import gamesReducer from './gamesSlice';
import tasksReducer from './tasksSlice';
import rewardsReducer from './rewardsSlice';
import initDataReducer from './initDataSlice';
import teamsReducer from './teamsSlice';
import leadersReducer from './leadersSlice';
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from 'redux';

const persistConfig = {
    key: "winPlay",
    storage,
};

const rootReducer = combineReducers({
    user: userReducer,
    friends: friendsReducer,
    games: gamesReducer,
    tasks: tasksReducer,
    rewards: rewardsReducer,
    teams: teamsReducer,
    leaders: leadersReducer,
    initData: initDataReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
