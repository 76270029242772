import React, {useCallback, useEffect, useRef, useState} from 'react';
import './DailyBonus.css';
import { useInitDataRaw } from "../../../../utils/tg";
import CountdownTimer from "./CountdownTimer";
import ConfettiController from '../../../app/components/ConfettiController';
import { claimBonus } from "../../../../api/rewards";
import {getTasks} from "../../../../api/tasks";
import {logError} from "../../../../utils/errorHandler";
import {setTasks} from "../../../../store/tasksSlice";
import {useDispatch} from "react-redux";

const DailyBonus = ({dailyLoginData}) => {
    const bonusContainerRef = useRef(null);
    const confettiRef = useRef(null);
    const dispatch = useDispatch();
    const handleSetTasks = useCallback((tasks) => dispatch(setTasks(tasks)), [dispatch]);

    const handleConfetti = () => {
        if (confettiRef.current) {
            confettiRef.current.startConfetti();
        }
    };

    const [isTimerMode, setIsTimerMode] = useState(!dailyLoginData.can_claim);


    useEffect(() => {
        const container = bonusContainerRef.current;
        const todayItem = container.querySelector('.bonus-item.today');

        if (todayItem && container) {
            const prevItem = todayItem.previousElementSibling;

            if (prevItem) {
                const scrollAmount = prevItem.offsetLeft - container.offsetLeft;
                container.scrollTo({
                    left: scrollAmount,
                    behavior: 'smooth',
                });
            }
        }
    }, []);

    const bonusAmounts = Array.from({length: 30}, (_, i) => dailyLoginData.first_day_reward + i * 100);

    const getLevelBonus = (day) => {
        return `+100 на ${dailyLoginData.user_lvl + 1} LVL`;
    };

    const days = Array.from({length: 30}, (_, i) => i + 1);
    const initDataRaw = useInitDataRaw();

    const fetchTasks = async () => {
        try {
            const response = await getTasks(initDataRaw);
            handleSetTasks(response.data);
        } catch (error) {
            logError(error.message, 'DailyBonus fetchTasks', 0, 0, error);
            console.error("Error fetching tasks:", error);
        }
    };

    const handleClaimBonus = async () => {
        await claimBonus(initDataRaw, null).then(response => {
            fetchTasks();
            handleConfetti();
            setIsTimerMode(true);
        });
    };

    return (
        <div className="daily-bonus">
            <h2>Заходи 30 дней и получай бонусы</h2>
            <div
                className="bonus-icons-container"
                ref={bonusContainerRef}
            >
                {days.map((day, index) => {
                    const isToday = day === dailyLoginData.current_streak;
                    const isActive = day < dailyLoginData.current_streak || (isToday && isTimerMode);


                    return (
                        <div
                            key={index}
                            className={`bonus-item ${isActive ? 'active' : ''} ${isToday ? 'today' : ''}`}
                        >
                            <span className={`day-label ${isToday ? 'today' : ''}`}>
                                {isToday ? 'Сегодня' : `День ${day}`}
                            </span>
                            <div className="circle-icon">
                                <img width="46" height="46"
                                    src={`/images/${isActive ? 'day-icon-yes.svg' : 'day-icon-no.svg'}`}
                                    alt="Bonus"
                                />
                            </div>
                            <div className="coins">
                                <img src="/images/coin100.png" width="100" height="100" alt="WCoin Icon"/>
                                <span>{bonusAmounts[index].toLocaleString()}</span>
                            </div>
                        </div>
                    );
                })}
                <ConfettiController ref={confettiRef}/>
            </div>
            {isTimerMode ?   <CountdownTimer/> : (
                <button className="claim-bonus-button" onClick={handleClaimBonus}>
                    Забрать {bonusAmounts[dailyLoginData.current_streak - 1].toLocaleString()} | {getLevelBonus(dailyLoginData.current_streak)}
                </button>
            )}
        </div>
    );
};

export default DailyBonus;
