import React from 'react';
import './ContestHeader.css';

const
    ContestHeader = () => {
    return (
        <div className="contest-header">
            <h1>Конкурсы</h1>
            <p className="alt-font">Зарабатывай WinCoins и выигрывай призы.<br />Чем больше WinCoins накопишь за день и месяц, тем выше шанс выиграть</p>
        </div>
    );
};

export default ContestHeader;
