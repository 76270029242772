import React, {useEffect, useRef} from 'react';
import './WinResult.css';
import ConfettiController from "../../../app/components/ConfettiController";
import {Link} from "react-router-dom";

const WinResult = ({prize}) => {
    const confettiRef = useRef(null);

    useEffect(() => {
        if (confettiRef.current) {
            confettiRef.current.startConfetti();
        }
    }, []);

    return (
        <div className="win-result">
            <ConfettiController ref={confettiRef}/>
            <div className="win-header">
                <img width="97" height="96" src="/images/gift.svg" alt="Подарок" className="win-result-gift-icon"/>
                <h1>Фрибет ваш!</h1>
                <p className="alt-font">Скоро он появится <br/> <span className="win-result-winline-link">в кабинете Winline</span></p>
            </div>
            <div className="win-result-prize-card">
                <div className="win-result-background"></div>
                <div className="win-result-background-filter"></div>

                <div className="win-result-prize-info">
                    <div className="win-result-info-inner">
                        <img width="95" height="95" src={"/images/prizes/"+prize+".png"} alt="Фишка" className="win-result-prize-icon"/>
                        <div className="win-result-prize-title">
                            <div className="win-result-title">Фрибет</div>
                            <div className="win-result-prize">{new Intl.NumberFormat('ru-RU').format(prize)}</div>
                        </div>
                    </div>
                </div>
            </div>
            <Link to="/" className="href-win-result-super-button">
                <button className="win-result-super-button">Супер!</button>
            </Link>
        </div>
    );
};

export default WinResult;
