import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: {
        total: 0,
        count: 0,
        my_link: '',
        friends: []
    },
};

export const friendsSlice = createSlice({
    name: 'friends',
    initialState,
    reducers: {
        setFriends: (state, action) => {
            state.value = action.payload;
        }
    },
});

export const { setFriends } = friendsSlice.actions;
export default friendsSlice.reducer;
