import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: '',
};

export const initDataSlice = createSlice({
    name: 'initData',
    initialState,
    reducers: {
        setInitData: (state, action) => {
            state.value = action.payload;
        }
    },
});

export const { setInitData } = initDataSlice.actions;
export default initDataSlice.reducer;
