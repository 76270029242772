import React from 'react';
import './UserList.css';
import UserItem from "../userItem/UserItem";
import {getProfilePhoto} from "../../../../../store/userSlice";
const UserList = ({ users }) => {

    const name = (user) => {
        return `${user.first_name} ${user.last_name}`;
    };

    return (
        <div className="user-list">
            {users.map((user, index) => (
                <UserItem
                    key={index}
                    name={name(user)}
                    reward_fixed={user.reward_fixed}
                    reward_passive={user.reward_passive}
                    avatar={getProfilePhoto(user.id,user.photo_ext)}
                />
            ))}
        </div>
    );
};

export default UserList;
