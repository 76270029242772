import React from 'react';
import './NavigationBar.css';
import {Link, useLocation} from 'react-router-dom';
import {useSelector} from "react-redux";
import {enableHapticFeedBack} from "../../../../utils/tg";

const NavigationBar = () => {
    const location = useLocation();
    const user = useSelector((state) => state.user.value);
    const tasks = useSelector((state) => state.tasks.value);

    const handleClick = () => {
        enableHapticFeedBack('heavy');
    };

    if (user?.can_play_lottery) {
        return null;
    }

    const getIconSrc = (baseName) => {
        return location.pathname === `/${baseName}`
            ? `/images/${baseName}-selected.svg`
            : `/images/${baseName}.svg`;
    };

    return (
        <div className="navigation-bar">
            <Link onClick={handleClick} to="/contests" className={`nav-item ${location.pathname === '/contests' ? 'active' : ''}`}>

                <img width={21} height={21} src={getIconSrc('contests')} alt="Конкурсы"/>
                <span>Конкурсы</span>

            </Link>
            <Link onClick={handleClick} to="/tasks" className={`nav-item ${tasks.daily_logins.can_claim ? '--has-action' : ''} ${location.pathname === '/tasks' ? 'active' : ''}`}>
                <img width={21} height={21} src={getIconSrc('tasks')} alt="Задания"/>
                <span>Задания</span>
            </Link>
            <Link onClick={handleClick} to="/" className={`nav-item ${location.pathname === '/' ? 'active-team' : ''}`}>

                <img width={25} height={25} src={user?.team?.logo_url} alt="Главная"/>
                <span>{user?.team?.short_name}</span>

            </Link>
            <Link onClick={handleClick} to="/friends" className={`nav-item ${location.pathname === '/friends' ? 'active' : ''}`}>

                <img width={21} height={21} src={getIconSrc('friends')} alt="Друзья"/>
                <span>Друзья</span>

            </Link>
            <Link onClick={handleClick} to="/leaders" className={`nav-item ${location.pathname === '/leaders' ? 'active' : ''}`}>

                <img width={21} height={21} src={getIconSrc('leaders')} alt="Лидеры"/>
                <span>Лидеры</span>

            </Link>
        </div>
    );
};

export default NavigationBar;