import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: '',
};

export const teamsSlice = createSlice({
    name: 'teams',
    initialState,
    reducers: {
        setTeams: (state, action) => {
            state.value = action.payload;
        }
    },
});

export const { setTeams } = teamsSlice.actions;
export default teamsSlice.reducer;