import React from 'react';
import './ReferralLink.css';
import {logError} from "../../../../utils/errorHandler";
import {useToast} from "../../../../contexts/ToastContext";



const ReferralLink = ({ text }) => {

    const notify = useToast();

    const handleCopy =  (event) => {
        event.target.classList.add('active_img');

        setTimeout(async () => {
            await navigator.clipboard.writeText(text).then(() => {
                notify('Скопировано', 'success');
            }).catch((err) => {
                logError(err.message, 'ReferralLink Ошибка копирования текста', 0, 0, err);
                console.error('Ошибка копирования текста:', err);
                event.target.classList.remove('active_img');
            }).finally(() => {

            });
        }, 100);
    };

    return (
        <div className="referral-section super-border">
            <span className="link">{text}</span>
            <img  width="40" height="40" src="/images/friends/copy.png" alt="Копировать" onClick={handleCopy} />
        </div>
    );
};

export default ReferralLink;
