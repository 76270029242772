import React from 'react';
import "./Questions.css";

export const Questions = () => {
    return (
        <div className="faq-questions">
            <hr/>
            <div className="faq-questions-block">
                <div className="faq-question">
                    <p className="faq-question-title">Как работает WinPlay?</p>
                    <p className="faq-question-text alt-font">
                        Пользователи зарабатывают монеты различными способами (тапы, задания, друзья) и повышают свои шансы на выигрыш призов, в конце дня и месяца происходят розыгрыши призов - чем
                        больше монет успел заработать пользователь - тем выше шанс на победу.
                    </p>
                </div>
                <div className="faq-question">
                    <p className="faq-question-title">Как происходит награждение</p>
                    <p className="faq-question-text alt-font">
                        Если пользователь привязал аккаунт Winline и выиграл фрибет, то приз автоматически появится в ЛК Winline в ближайшее время. Если пользователь выиграл приз, но у него не
                        привязан аккаунт Winline - фрибет будет начислен как только пользователь привяжет аккаунт.
                    </p>
                </div>
                <div className="faq-question">
                    <p className="faq-question-title">Что значат "шансы на победу" и как работают розыгрыши</p>
                    <p className="faq-question-text alt-font">
                        Шансы на победу - это оценочные шансы на выигрыш призов. Розыгрыш призов осуществляется автоматически с помощью внутреннего алгоритма WinPlay. Для каждого приза, начиная с
                        самого крупного, проводится отдельный розыгрыш, розыгрыш останавливается как только один из призов выигран, т.е. в одном розыгрыше возможно выиграть только 1 приз. Пользователь участвует в розыгрыше только если он был активен в этот день (совершал клики).
                    </p>
                </div>
                <div className="faq-question">
                    <p className="faq-question-title">Что такое уровни (lvl)?</p>
                    <p className="faq-question-text alt-font">
                        Уровень зависит от общего кол-ва заработанных пользователем монет. С каждым новым уровнем увеличивается максимальный запас энергии и кол-во получаемых за тап монет (+1).
                    </p>
                </div>

            </div>
        </div>
    );
};
