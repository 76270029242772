import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: '',
};

export const leadersSlice = createSlice({
    name: 'leaders',
    initialState,
    reducers: {
        setLeaders: (state, action) => {
            state.value = action.payload;
        }
    },
});

export const { setLeaders } = leadersSlice.actions;
export default leadersSlice.reducer;