import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: {
        tasks: [],
        daily_logins: {
            current_streak: 0,
            current_reward: 0,
            can_claim: false,
            first_day_reward: 0
        },
        is_winline_linked: false
    },
};

export const tasksSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        setTasks: (state, action) => {
            state.value = action.payload;
        }
    },
});

export const { setTasks } = tasksSlice.actions;
export default tasksSlice.reducer;
