import React from 'react';
import './UserList.css';
import UserItem from "../userItem/UserItem";
import {getProfilePhoto} from "../../../../store/userSlice";

const UserList = ({ users }) => (
    <div className="user-list">
        {users.map((user, index) => (
            <UserItem
                key={index}
                position={index + 1}
                name={user.name}
                points={user.coins}
                avatar={getProfilePhoto(user.id,user.photo_ext)}
            />
        ))}
    </div>
);

export default UserList;
