import React from 'react';
import './UserItem.css';

const UserItem = ({position, name, points, avatar}) => (
    <div className="user-item super-border">

        <img width="24" height="24" className="user-avatar" src={avatar} alt="avatar"/>

        <div className="user-info">
            <span className="user-name"><span>{position}.</span> {name}</span>
            <span className="user-earnings">
                <div className="user-total">
                    <img src="/images/coin100.png" width="100" height="100" alt="WCoin" className="wcoin-icon"/>
                    {points.toLocaleString()}
                </div>
            </span>
        </div>
    </div>
);

export default UserItem;
