import React, { useState, useEffect } from 'react';

const CountdownTimer = () => {
    const getTimeRemaining = () => {
        const now = new Date();
        const currentTimeInMSK = new Date(now.toLocaleString('en-US', { timeZone: 'Europe/Moscow' }));
        const endOfDay = new Date(currentTimeInMSK);
        endOfDay.setHours(23, 59, 59, 999); // Устанавливаем конец дня 23:59:59

        const timeLeft = endOfDay - currentTimeInMSK;
        if (timeLeft <= 0) {
            return 'День закончился';
        } else {
            const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
            const seconds = Math.floor((timeLeft / 1000) % 60);
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        }
    };

    const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeRemaining(getTimeRemaining());
        }, 1000);

        return () => clearInterval(interval); // Очищаем таймер при размонтировании компонента
    }, []);

    return (
        <div className="countdown-timer">
            {timeRemaining}
        </div>
    );
};

export default CountdownTimer;
