import React, {useEffect, useState} from 'react';
import './Energy.css';

const Energy = ({currentEnergy, maxEnergy}) => {

    const calculateY = (currentEnergy, maxEnergy) => {

        let percentage = (currentEnergy / maxEnergy * 100).toFixed(2);

        let start = 15
        let end = 88
        return end - ((end - start) * (percentage / 100))
    }

    const [y, setY] = useState(calculateY(currentEnergy, maxEnergy));


    useEffect(() => {
        setY(calculateY(currentEnergy, maxEnergy))

    }, [currentEnergy, maxEnergy]);


    return (
        <div className="tap-energy">

            <svg width="30" height="30" viewBox="0 0 100 100" style={{"transform": "rotate(130deg)"}}>
                <path opacity="0.3"
                      d="m97.1 16.45-.96-.95c-2.86-2.55-9.86-2.55-23.85-2.55l-35.93.32c-10.81 0-15.9 0-19.08 2.23q-3.18 3.18-3.5 7.95c0 3.5 3.18 7.63 10.18 15.9l1.59 2.22q2.86 3.18 3.18 4.45v1.6q0 .95-.64 1.58-.32 1.28-3.82 3.82l-6.36 5.09C7.11 67 1.38 71.47.74 75.28a9.5 9.5 0 0 0 3.18 9.22c3.18 2.55 10.18 2.55 24.17 2.55l35.93-.32c10.82 0 15.9 0 19.08-2.23a9.5 9.5 0 0 0 3.5-7.95c0-3.5-3.18-7.63-10.17-15.9l-1.6-2.54q-2.86-3.18-3.17-4.45l.31-3.18 3.82-3.5 6.36-5.4c11.13-8.6 16.54-13.04 17.17-17.18a9.5 9.5 0 0 0-2.22-7.95"
                      fill="#ed7333"/>

                <defs>
                    <clipPath id="energyMask">
                        <rect
                            x="0"
                            y={y}
                            width="100"
                            height="80"
                        />
                    </clipPath>
                </defs>

                <path
                    d="m97.1 16.45-.96-.95c-2.86-2.55-9.86-2.55-23.85-2.55l-35.93.32c-10.81 0-15.9 0-19.08 2.23q-3.18 3.18-3.5 7.95c0 3.5 3.18 7.63 10.18 15.9l1.59 2.22q2.86 3.18 3.18 4.45v1.6q0 .95-.64 1.58-.32 1.28-3.82 3.82l-6.36 5.09C7.11 67 1.38 71.47.74 75.28a9.5 9.5 0 0 0 3.18 9.22c3.18 2.55 10.18 2.55 24.17 2.55l35.93-.32c10.82 0 15.9 0 19.08-2.23a9.5 9.5 0 0 0 3.5-7.95c0-3.5-3.18-7.63-10.17-15.9l-1.6-2.54q-2.86-3.18-3.17-4.45l.31-3.18 3.82-3.5 6.36-5.4c11.13-8.6 16.54-13.04 17.17-17.18a9.5 9.5 0 0 0-2.22-7.95"
                    fill="#ed7333" clipPath="url(#energyMask)"/>

            </svg>

            <div className="energy-text">
                <span className="current-energy">{currentEnergy}</span>
                <span className="max-energy">/{maxEnergy}</span>
            </div>
        </div>
    );
};

export default Energy;
