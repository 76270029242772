import React from 'react';
import {getPercentageColor} from "../../../app/components/PercentageColor";

const PrizeCard = ({ amount, chance, isActive }) => {
    return (
        <div className={`prize-card super-border ${isActive ? '--selected' : ''}`}>
            <div className="prize-info">
                <div className="prize-title">
                    <div className="title">Фрибет</div>
                    {new Intl.NumberFormat('ru-RU').format(amount)}
                </div>
                <img width="63" height="62" src={'/images/prizes/' + amount + '.png'} alt="Фишка" className="prize-icon" />
            </div>
            <div className="prize-chance">
                <div
                    className="chance-bar"
                    style={{
                        '--bar-color': getPercentageColor(chance),
                        '--bar-width': `${chance}%`
                    }}
                ></div>
                <p><span className="chance">шанс:</span> {chance.toFixed(2)}%</p>
            </div>
        </div>
    );
};

export default PrizeCard;
