import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: {
        rewards: [],
    },
};

export const rewardsSlice = createSlice({
    name: 'rewards',
    initialState,
    reducers: {
        setRewards: (state, action) => {
            state.value = action.payload;
        }
    },
});

export const { setRewards } = rewardsSlice.actions;
export default rewardsSlice.reducer;
