import React from 'react';
import './ErrorScreen.css';

const ErrorScreen = () => {
    const handleRetry = () => {
        window.location.reload();
    };

    return (
        <div className="error-container">
            <img src="/images/error.png" alt="Error" className="error-image" />
            <h2>Загрузить игру не получилось</h2>
            {/*<p>Повторите попытку ещё раз</p>*/}
            {/*<button className="retry-button" onClick={handleRetry}>*/}
            {/*    Повторить загрузку*/}
            {/*</button>*/}
        </div>
    );
};

export default ErrorScreen;
