import React from 'react';
import './UserItem.css';

const UserItem = ({name, reward_fixed, reward_passive, avatar}) => {
    return (
        <div className="user-item super-border">
            <div className="user-avatar">
                <img width="35" height="35" src={avatar} alt="avatar"/>
            </div>
            <div className="user-info">
                <span className="user-name">{name}</span>
                <span className="user-earnings">
                    <div className="user-total">
                    <img src="/images/coin100.png" width="100" height="100" alt="WCoin" className="wcoin-icon"/>
                        {(reward_fixed + reward_passive).toLocaleString()}
                        </div>
                    <div className="user-details">
                                {reward_fixed.toLocaleString()} + {reward_passive.toLocaleString()}
                    </div>
                </span>
            </div>
        </div>
    );
};

export default UserItem;
