import React from 'react';
import './TeamItem.css';

const TeamItem = ({ team, isSelected, onSelect }) => (
    <div
        className="team-item"
        onClick={onSelect}
    >
        <div className={`team-logo super-border ${isSelected ? '--selected' : ''}`}>
            <img src={team.logo_url} alt={team.name} />
        </div>
        <div className="team-info">
            <div className={`team-name ${isSelected ? 'selected' : ''}`}>{team.name}</div>
            <span className="team-members">{team.players_count} человек</span>
        </div>
    </div>
);

export default TeamItem;
