import React, { useEffect } from 'react';
import './LayoutMain.css'
import { disableConfirmation, disableSwipes, hideBackButton } from "../../../../utils/tg";

const Layout = ({ children, addClass = '' }) => {
    useEffect(() => {
        hideBackButton();
        disableConfirmation();
        disableSwipes();
    }, []);

    return (
        <div className="main-layout-container">
            <main className={"main-content" + (addClass ? ' '+addClass : "")}>
                {children}
            </main>
        </div>
    );
};

export default Layout;
