import React from 'react';
import ReactDOM from 'react-dom/client';
import {router} from "./router";
import {RouterProvider} from "react-router-dom";
import {persistor, store} from './store/store';
import {Provider} from 'react-redux';
import './index.css';
import {init} from './init';
import './mockEnv';
import {ToastProvider} from './contexts/ToastContext'
import {ToastContainer} from 'react-toastify';
import {PersistGate} from 'redux-persist/integration/react';
import {EnvUnsupported} from "./modules/app/components/EnvUnsupported";
import {setupGlobalErrorHandler} from "./utils/errorHandler";
import ErrorScreen from "./modules/app/components/errorScreen/ErrorScreen";

const root = ReactDOM.createRoot(document.getElementById('root'));

setupGlobalErrorHandler();


    root.render(
        <ErrorScreen/>

    )


// try {
//     init()
//     root.render(
//         <ToastProvider>
//             <ToastContainer position="top-center" hideProgressBar={true}
//                             newestOnTop={false} theme="dark"
//                             closeOnClick></ToastContainer>
//             <Provider store={store}>
//                 <PersistGate loading={null} persistor={persistor}>
//                     <RouterProvider router={router}/>
//                 </PersistGate>
//             </Provider>
//         </ToastProvider>
//     )
//
// } catch (e) {
//     root.render(<EnvUnsupported/>);
// }
