import {CQFetchPOST} from '../api/logError';

export function logError(message, source, lineno, colno, error) {

    let errorData = {
        url: document.location.href,
        error: error.stack,
        message: message,
        source: source,
        lineno: lineno,
        userAgent: navigator.userAgent,
        screen: getWidth() + 'x' + getHeight(),
    };

    console.error(errorData);

    CQFetchPOST(errorData)

    return true;
}

function getWidth() {
    if (window.innerWidth) {
        return window.innerWidth;
    }

    if (document.documentElement && document.documentElement.clientWidth) {
        return document.documentElement.clientWidth;
    }

    if (document.body) {
        return document.body.clientWidth;
    }

    return '?'
}

function getHeight() {
    if (window.innerHeight) {
        return window.innerHeight;
    }

    if (document.documentElement && document.documentElement.clientHeight) {
        return document.documentElement.clientHeight;
    }

    if (document.body) {
        return document.body.clientHeight;
    }

    return '?'
}

export function setupGlobalErrorHandler() {
    window.onerror = logError;
}
