import React, {useEffect} from 'react';
import "./Faq.css"
import LayoutMain from "../app/components/layout/LayoutMain";
import { Information } from "./components/information/Information";
import { Questions } from "./components/questions/Questions";
import {initBackButton} from "../../utils/tg";

const Faq = () => {
    useEffect(() => {
        initBackButton();
    }, []);

    return (
        <LayoutMain  addClass="no-padding-bottom">
           <div className="faq-container">
               <Information />
               <Questions />
           </div>
        </LayoutMain>
    );
};

export default Faq;
